(function () {
  'use strict';

  angular
    .module('smAnonymousSafetyReport')
    .config(appConfig);

  appConfig.$inject = ['$stateProvider', '$urlRouterProvider'];


  function appConfig($stateProvider, $urlRouterProvider) {

    $urlRouterProvider.otherwise('/');
    $stateProvider
      .state('main', {
        url: '/',
        templateUrl: 'site-picker.html',
        controller: 'sitePickerController as vm',
      })
      .state('main.reportForm', {
        url: 'report',
        templateUrl: 'anonymous-report-form.html',
        controller: 'reportFormController as vm',
        params: { site: null },
      });
  }

  angular
    .module('smAnonymousSafetyReport')
    .service('currentSiteService', currentSiteService);

  function currentSiteService () {
    return false;
  }

  angular
    .module('smAnonymousSafetyReport')
    .service('loggedInUserService', loggedInUserService);

  function loggedInUserService () {
    return false;
  }

})();
