/* global SYSTEM */

function dynamicImportHTML2Canvas () {
  return import(/* webpackChunkName: "html2canvas" */ 'html2canvas');
}


(function() {
  'use strict';

  angular
    .module('blocks.errorHandler')
    .factory('errorHandler', errorHandler);

  errorHandler.$inject = [
    'ERROR_HANDLER_SENDS_EMAIL',
    '$anchorScroll', '$document', '$http',
    '$state', '$timeout',
    'currentSiteService', 'locationHelper',
    'loggedInUserService', 'logger'];

  function errorHandler(
    ERROR_HANDLER_SENDS_EMAIL,
    $anchorScroll, $document, $http,
    $state, $timeout,
    currentSiteService, locationHelper,
    loggedInUserService, logger) {

    var all = [];

    return {
      catcher,
      getHandler,
      clearHandlerForController,
      prepareHandlerForController,
      prepareHandlerForObject: prepareHandlerForController,
    };

    ////

    function catcher(handlerName, controller) {
      return function requestFailed(error) {
        prepareHandlerForController(handlerName, controller);
        controller[handlerName].addError(error);
        return error;
      };
    }

    function clearHandlerForController(handlerName, controller) {
      if (controller && controller[handlerName]) {
        controller[handlerName].clearErrors();
      }
    }

    function prepareHandlerForController(handlerName, controller, scrollElementId) {
      controller[handlerName] = controller[handlerName] || getHandler(controller, scrollElementId);
    }

    function getHandler(controller, scrollElementId='error-handler') {

      var handler = {
        addError,
        clearErrors,
        controller: safeAngularCopy(controller),
        emailSent: false,
        status: null,
        errors: [],
        detailsVisible: false,
        latestErrorMessage: '',
        sendingEmail: false,
        toggleDetailsVisibility,
      };

      all.push(handler);

      return handler;

      ////

      function addError(error) {

        if (handler.controller) {
          error.controller = getPreparedController();
        }

        handler.errors.push(JSON.stringify(error, null, 2));

        if (handler.errors.length === 1) {
          handler.status = error.data && error.data.status;
          if (!errorCausedByBadConnection()) {
            logger.error(error);
            tryToSendEmail();
          }
        }

        $timeout(function() {
          if ($document.scrollToElementAnimated) {
            var element = $('#' + scrollElementId);
            if (element.length) {
              locationHelper.scrollTo(element, {
                scrollOffset: 'thirdway',
                scrollOnlyIfOutOfSight: true,
              });
            }
          } else {
            $anchorScroll(scrollElementId);
          }
        }, 0);

        function errorCausedByBadConnection() {
          return error.data === null ||
            error.data &&
            (error.data.data === null ||
             error.data.data &&
             error.data.data.data === null);
        }

        function getEmailJson() {

          var body = {};

          if (loggedInUserService && loggedInUserService.loggedInUser) {
            body.aSummary = getSummary();
          }

          body['window.location.href'] = window.location.href;
          body['navigator.appVersion'] = navigator.appVersion;
          body['navigator.userAgent'] = navigator.userAgent;
          body['navigator.platform'] = navigator.platform;
          body.error = error;
          body.stateCurrent = $state.current;
          body.stateHistory = $state._history;

          if (loggedInUserService && loggedInUserService.loggedInUser) {
            body.loggedInUser = loggedInUserService.loggedInUser;
          }

          return body;

          function getSummary() {
            return {
              firstName: loggedInUserService.loggedInUser.firstName,
              lastName: loggedInUserService.loggedInUser.lastName,
              emailAddress: loggedInUserService.loggedInUser.emailAddress,
              phoneNumber: loggedInUserService.loggedInUser.phoneNumber,
              target: _.get(loggedInUserService, ['loggedInUser', 'target', 'name']),
              currentSite: _.get(currentSiteService, ['site', 'name']),
              system: SYSTEM,
            };
          }

        }

        function getEmailSubject() {
          var subject = 'Fail: ';

          if (loggedInUserService && loggedInUserService.loggedInUser) {
            subject += _.get(loggedInUserService, ['loggedInUser', 'firstName']) + ' ' +
              _.get(loggedInUserService, ['loggedInUser', 'lastName']) + ' / ' +
              _.get(loggedInUserService, ['loggedInUser', 'target', 'name']) + ' (' + SYSTEM + ') @ ';
          } else {
            subject += 'unknown user ';
          }

          subject += getErrorMessageFromData(error);
          subject += window.location.href;
          return subject;

          function getErrorMessageFromData(data, previousData = {}) {
            var str = '';
            if (data && (data.status || data.message)) {
              if (data.status &&
                  data.status !== previousData.status) {
                str += data.status + ' ';
              }
              if (data.message &&
                  data.message !== previousData.message) {
                str += data.message + ' ';
                if (data.exception) {
                  str += '(' + data.exception + ') ';
                }
              }
              str += '/ ';
              if (data.data) {
                str += getErrorMessageFromData(data.data, data);
              }
              return str;
            } else {
              return '';
            }
          }

        }

        function tryToSendEmail() {

          if (!ERROR_HANDLER_SENDS_EMAIL) {
            return;
          }

          const errorJson = getEmailJson();
          const htmlBegins = '<!doctype html><html><head></head><body>';
          const errorHtml = '<pre><code>' +
            JSON.stringify(errorJson, null, 2) +
            '</pre></code><br>';
          const htmlEnds = '</body></html>';
          let imgHtml = '';
          const sender = 'sitemanager.error.bot@zeroni.fi';
          var params = {
            sender,
            header: getEmailSubject(),
          };

          dynamicImportHTML2Canvas()
            .then(html2canvas => {

              html2canvas.default(document.body)
                .then(includeScreenshot)
                .catch(() => {}) // Don't do anything if screenshot fails
                .finally(sendEmail);
            });

          function includeScreenshot(canvas) {
            imgHtml = '<img src="' + canvas.toDataURL() + '" style="max-width:100%;">';
          }

          function sendEmail() {
            params.text = htmlBegins + imgHtml + errorHtml + htmlEnds;
            handler.sendingEmail = true;
            $http.put('erroremail', params)
              .then(function() {
                logger.info('Error email sent', errorJson);
                handler.sendingEmail = false;
                handler.emailSent = true;
              })
              .catch(() => {
                // Failed to send error email
              });
          }

        }

        function getPreparedController() {

          return removeAngularInternalVars(angular.copy(handler.controller));

          function removeAngularInternalVars(obj) {
            angular.forEach(obj, function(value, key) {
              if (typeof key === 'string' &&
                  key.length > 1 &&
                  (key.substring(0, 2) === '$$' || key === '_ancestors')) {
                delete obj[key];
              } else if (typeof value === 'object') {
                removeAngularInternalVars(obj[key]);
              }
            });
            return obj;
          }

        }

      }

      function clearErrors() {
        handler.errors = [];
        handler.detailsVisible = false;
        handler.latestErrorMessage = '';
      }

      function safeAngularCopy(object, path = 'root') {

        const objectCopy = {};
        angular.forEach(object, function(property, name) {
          if (!angular.isObject(property) || angular.isArray(property)) {
            try {
              objectCopy[name] = angular.copy(property);
            } catch (err) {
              console.error('Cannot create safeAngularCopy of ' + path + '.' + name + ', err: ' + err); // eslint-disable-line no-console
            }
          } else if (!objectIsAngularScopeOrWindow(property)) {
            objectCopy[name] = safeAngularCopy(property, path + '.' + name);
          }
        });
        return objectCopy;

        function objectIsAngularScopeOrWindow(obj) {

          return isAngularScope(obj) || isWindow(obj);

          function isAngularScope(obj) {
            let isScope = false;
            angular.forEach(obj, function(property, name) {
              if (name.length > 2 && name.substr(0, 2) === '$$') {
                isScope = true;
              }
            });
            return isScope;
          }

          function isWindow(obj) {
            return obj && obj.document && obj.location && obj.alert && obj.setInterval && true || false;
          }

        }

      }

      function toggleDetailsVisibility() {
        handler.detailsVisible = !handler.detailsVisible;
      }
    }

  }

})();
