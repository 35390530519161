(function() {

  angular
    .module('blocks.i18n')
    .directive('i18nPlaceholder', i18nPlaceholder);

  i18nPlaceholder.$inject = ['i18nService'];

  function i18nPlaceholder(i18nService) {
    return {
      template: '<input/>',
      link(scope, element, attrs) {
        const message = i18nService.get(attrs.i18nPlaceholder);
        element.attr('placeholder', message);
      },
    };
  }

})();
