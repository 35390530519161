(function() {
  'use strict';

  angular
    .module('blocks.dropsearch')
    .controller('docsUploadController', docsUploadController);

  docsUploadController.$inject = ['$attrs', '$element', '$http', '$parse',
    '$scope', '$timeout'];

  function docsUploadController($attrs, $element, $http, $parse,
    $scope, $timeout) {

    $scope.dropzoneId = $parse($attrs.dropzoneId)($scope) || null;
    $scope.onDrop = $parse($attrs.onDrop);
    $scope.onDestroy = $parse($attrs.onDestroy);
    $scope.options = getUploadOptions(); // BlueImp file upload options
    $scope.title = $attrs.title;
    $scope.uploadOnly = !!$attrs.hasOwnProperty('uploadOnly');


    $scope.$watch($attrs.ngDisabled, function () {
      parseDisabled();
      $scope.options = getUploadOptions();
    });

    function parseDisabled() {
      if ($attrs.ngDisabled && $attrs.ngDisabled !== 'false') {
        const isDisabled = $parse($attrs.ngDisabled)($scope);
        $scope.ngDisabled = isDisabled;
        $scope.disabled = isDisabled;
      }
    }

    $scope.showPreview = $attrs.hasOwnProperty('preview');

    var attachTo = $attrs.attachTo || '';

    const maxRetryCount = 5;
    $scope.retriesCount = maxRetryCount;
    activate();

    ////

    function activate() {

      $scope.$watch('queue', setAsRequiredIfNeeded, true);
      $scope.$watch($attrs.dropzoneId, setDropzoneIdToScope);
      $scope.$watch('dropzoneId', dropzoneIdWatcher);
      $scope.$watch('previewDataUrl', setPreview);
      // Huom! (ng-)required-parametri direktiivissä kertoo onko tiedostoja pakko lisätä vai voiko dropzonen jättää tyhjäksi.
      // Tätä käsitellään $scope.filesRequiredina.
      // $scope.required kertoo onko dropzone virheellisessä tilassa koska tiedostoja ei ole.
      if ($attrs.hasOwnProperty('required')) {
        $scope.filesRequired = true;
      }
      if ($attrs.hasOwnProperty('ngRequired')) {
        $scope.$watch(getNgRequiredValue, ngRequiredValueWatcher);
      }
    }

    function setAsRequiredIfNeeded() {
      var queue = $scope.queue;
      if (queue && queue.length) {
        $scope.required = false;
      } else {
        if ($scope.filesRequired) {
          $scope.required = true;
        } else {
          $scope.required = false;
        }
      }
    }

    function getNgRequiredValue() {
      return $parse($attrs.ngRequired)($scope);
    }

    function ngRequiredValueWatcher(ngRequiredNewValue) {
      $scope.filesRequired = ngRequiredNewValue;
      setAsRequiredIfNeeded();
    }

    function getUploadOptions() {

      const retryTimeout = 2000;

      var options = {
        url: getUrl(),
        autoUpload: true,
        maxFileSize: 20000000, // 20 MB,
        //maxRetries: 100, // this doesn't seem to work
        retryTimeout,
        change () {
          $scope.retriesCount = maxRetryCount;
        },
        fail (e, data) {
          if ($scope.retriesCount > 0) {
            $scope.retriesCount--;
            $timeout(retry, retryTimeout);
          }
          function retry() {
            data.submit();
          }
        },
      };
      if ($attrs.maxSize) {
        var maxImageHeight = $attrs.maxSize;
        var maxImageWidth = $attrs.maxSize;
        options.disableImageResize = false;
        options.disableImageMetaDataSave = true;
        options.imageMaxWidth = maxImageWidth;
        options.imageMaxHeight = maxImageHeight;
        options.previewMaxWidth = maxImageWidth;
        options.previewMaxHeight = maxImageHeight;
      }
      if ($attrs.hasOwnProperty('limitDropzoneArea')) {
        options.dropZone = $($element);
      }
      if ($scope.disabled) {
        options.dropZone = null;
      }
      return options;
    }

    function getFileRefsUrl() {
      return $attrs.uploadUrl + '/' + $scope.dropzoneId + '/fileRefs';
    }

    function getUrl() {
      var url = $attrs.uploadUrl + '/';
      if ($scope.dropzoneId) {
        url = url + $scope.dropzoneId;

        if ($attrs.hasOwnProperty('noResize')) {
          url = url + '?resize=false';
        } else {
          url = url + '?resize=true';
        }
      }
      return url;
    }

    function dropzoneIdWatcher(newDropzoneId) {

      saveDropzoneId(newDropzoneId);
      $scope.options = getUploadOptions();

      if (newDropzoneId) {
        $scope.$emit('DropzoneIdAdded');
      }
      if (newDropzoneId && !$scope.uploadOnly) {
        $scope.loadingFiles = true;
        $http.get(getFileRefsUrl())
          .then(
            function (response) {
              $scope.loadingFiles = false;
              $scope.queue = response.data || [];
              prepareAttachments();
            },
            function () {
              $scope.loadingFiles = false;
            },
          );
      } else {
        $scope.queue = [];
        prepareAttachments();
      }

    }

    function prepareAttachments() {
      if (attachTo !== '') {
        var path = $attrs.dropzoneId.split('.');
        var hostObject = $parse(attachTo)($scope);
        hostObject.attachments = hostObject.attachments || {};
        var propName = $attrs.attachmentName || path[path.length - 1];
        hostObject.attachments[propName] = $scope.queue;
      }
    }

    function saveDropzoneId(dropzoneId) {
      $parse($attrs.dropzoneId).assign($scope, dropzoneId);
    }

    function setDropzoneIdToScope(newDropzoneId) {
      $scope.dropzoneId = newDropzoneId;
    }

    function setPreview(preview) {
      if ($attrs.preview) {
        $parse($attrs.preview).assign($scope, preview);
      }
    }

  }
})();
