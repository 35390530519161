import angular from 'angular';
import loggerService from '../../inc/js/react/services/loggerService';
import errorService from '../../inc/js/react/services/errorService';

angular
  .module('blocks.logger')
  .factory('logger', importedLoggerService);

function importedLoggerService() {
  return { ...loggerService, ...errorService };
}
