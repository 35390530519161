(function() {
  'use strict';

  angular
    .module('blocks.dropsearch')
    .controller('docsDestroyController', docsDestroyController);

  /* @ngInject */
  function docsDestroyController($scope, $http) {

    const vm = this;
    const file = $scope.file || $scope.queue[0];
    vm.destroy = function () {
      if ($scope.uploadOnly) {
        return;
      }
      vm.state = 'pending';
      return deleteFile().then(deleteResolved, deleteRejected);
    };

    function deleteFile() {
      return $http({
        url: '/documents/files/' + file.id,
        method: 'DELETE',
      });
    }

    function deleteResolved() {
      vm.state = 'resolved';
      $scope.clear(file);
      $scope.onDestroy($scope, { dropzoneId: $scope.dropzoneId, eventType: 'destroy', files: file });
      $scope.$emit('documentDestroyed', $scope.dropzoneId);
    }

    function deleteRejected() {
      vm.state = 'rejected';
    }

  }

})();
