(function () {
  'use strict';

  angular
    .module('smAnonymousSafetyReport')
    .controller('sitePickerController', sitePickerController);

  sitePickerController.$inject = ['$geolocation', '$http', '$q', '$rootScope', '$state', '$cookies'];

  function sitePickerController($geolocation, $http, $q, $rootScope, $state, $cookies) {

    $rootScope.$state = $state;
    $rootScope.splashStarted = true;
    var vm = this;

    var DEFAULT_RADIUS = 50;
    var EXTENDED_RADIUS = 500;
    var URL = '/monitoring/anonymous/api/v1/sites';

    vm.gettingLocation = true;
    vm.reload = reload;
    vm.showMore = showMore;
    vm.languages = [{ name: 'FI', id: 'fi' }, { name: 'EN', id: 'en' }, { name: 'ET', id: 'et' }, { name: 'RU', id: 'ru' }];
    vm.changeLanguage = changeLanguage;

    activate();

    function changeLanguage(newLanguage) {
      if (!newLanguage || newLanguage === vm.currentLanguage) {
        return;
      }

      emptyLangFromCookies();
      window.location.href = '?lang=' + newLanguage;

      function emptyLangFromCookies() {
        $cookies.remove('lang');
      }
    }

    function activate() {
      vm.gettingLocation = true;

      setLocale();
      if (vm.askLanguage) {
        return;
      }

      vm.currentLanguage = $cookies.get('lang');

      return getLocation()
        .catch(getLocationFailed)
        .then(getSites)
        .catch(getSitesFailed);

      function setLocale() {
        let locale = getLanguageOfBrowser();
        let langCode = locale.split('-')[0];

        if (!$cookies.get('lang')) {
          if (langCode === 'en') {
            $cookies.put('lang', 'en');
          } else if (langCode === 'et') {
            $cookies.put('lang', 'et');
          } else if (langCode === 'ru') {
            $cookies.put('lang', 'en');
          } else if (langCode === 'fi') {
            $cookies.put('lang', 'fi');
          } else {
            vm.askLanguage = true;
            return;
          }
        }
      }

      function getLanguageOfBrowser() {
        return navigator.languages
          ? navigator.languages[0]
          : (navigator.language || navigator.userLanguage);
      }

      function getLocation() {

        vm.gettingLocation = true;

        return $geolocation.getCurrentPosition({ enableHighAccuracy: true })
          .then(getLocationComplete)
          .catch(getLocationFailed);

        function getLocationComplete(position) {
          vm.location = position;
          vm.gettingLocation = false;
        }

      }

      function getLocationFailed() {

        $rootScope.splashFinished = true;
        vm.geolocationFailed = true;
        vm.gettingLocation = false;
        getGeolocationInstructions();

        function getGeolocationInstructions() {

          var userAgent = navigator.userAgent;
          var instructionUrls = {
            ios: 'https://support.apple.com/fi-fi/HT207092',
            android: 'https://support.google.com/accounts/answer/3467281?hl=fi',
          };

          if (/Android/i.test(userAgent)) {
            vm.geolocationInstructionsUrl = instructionUrls.android;
          } else if (/iPhone|iPad/i.test(userAgent)) {
            vm.geolocationInstructionsUrl = instructionUrls.ios;
          }

        }

      }

      function getSites() {

        if (!_.get(vm, ['location', 'coords'])) {
          return $q.reject('No coords');
        }

        vm.gettingSites = true;

        return $http.get(URL, { params: getParams() })
          .then(getSitesComplete);

        function getSitesComplete(response) {
          vm.sites = response.data;
          $rootScope.splashFinished = true;
          vm.gettingSites = false;
          getSitesWithExtendedRange();
        }

      }

      function getSitesWithExtendedRange() {

        return $http.get(URL, { params: getParams(EXTENDED_RADIUS) })
          .then(getSitesWithExtendedRangeComplete)
          .catch(function() {});

        function getSitesWithExtendedRangeComplete(response) {
          var extendedRangeSites = response.data;
          vm.moreSites = extendedRangeSites.filter(isNewToList);
          function isNewToList(extendedRangeSite) {
            return !vm.sites.find(matchingId);
            function matchingId(site) {
              return site.id === extendedRangeSite.id;
            }
          }
        }

      }

      function getSitesFailed() {
        $rootScope.splashFinished = true;
        vm.sitesFailed = true;
        vm.gettingSites = false;
      }

    }

    function getParams(radius) {
      return {
        lat: _.get(vm, ['location', 'coords', 'latitude']),
        lon: _.get(vm, ['location', 'coords', 'longitude']),
        radius: radius || DEFAULT_RADIUS,
      };
    }

    function reload () {
      vm.geolocationFailed = false;
      vm.radius = DEFAULT_RADIUS;
      delete vm.location;
      delete vm.sites;
      activate();
    }

    function showMore() {
      while (vm.moreSites.length) {
        vm.sites.push(vm.moreSites.shift());
      }
    }

  }

})();
