/* eslint-disable no-console */
import issueMonitoringService from './issueMonitoringService';

// The service is defined all the way up here so that its methods
// can be mocked in tests.
const loggerService = {
  error,
  info,
  warning,
};

/**
 * Handles logging and reporting of errors.
 *
 * @param {Error | string} error - Error object or message.
 * @param {*} data - Additional data related to the error.
 * @param {Object} params - Additional parameters for error handling.
 */
function error(error, data, params) {
  if (!shouldBeReported(error, data, params)) {
    return;
  }

  console.error(error, data);

  if (error instanceof Error || typeof error === 'string') {
    issueMonitoringService.error(error, {
      data: error.data || error.message,
      additionalData: data,
    });
  }

}

/**
 * Determines whether an error should be reported.
 *
 * @param {Error | string} error - Error object or message.
 * @param {*} data - Additional data related to the error.
 * @param {Object} params - Additional parameters for error handling.
 * @returns {boolean} - Returns true if the error should be reported.
 */
function shouldBeReported(error, data, params) {
  const skipStatusArr = params?.skipReportForStatus;
  const status = data?.status || error?.status;
  return (!(skipStatusArr && status && _.invoke(skipStatusArr, 'includes', status)));
}

/**
 * Wrapper for console.info
 *
 * @param {string || Error} message
 * @param {*} data
 */
function info(message, data) {
  if (data) {
    console.info(message, data);
  } else {
    console.info(message);
  }
}


/**
 * Wrapper for console.warn
 *
 * @param {string || Error} warning
 * @param {*} data
 */
function warning(warning, data) {
  if (warning instanceof Error) {
    issueMonitoringService.warning(warning, {
      beforeSend (report) {
        report.updateMetaData('data', data);
      },
    });
    console.warn(warning.message, data);
  } else {
    console.warn(warning, data);
  }
}

export default loggerService;
