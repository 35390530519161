function getDateParts(dateParam) {

  let year, month, day;

  if (Array.isArray(dateParam)) {
    prepareFromArray();
  } else if (typeof dateParam === 'string') {
    prepareFromString();
  } else {
    prepareFromTimestamp();
  }

  return {
    year,
    month,
    day,
  };

  function prepareFromArray() {
    year = dateParam[0];
    month = dateParam[1];
    day = dateParam[2];
  }

  function prepareFromString(param = dateParam) {

    const separator = getSeparator();
    const dateParts = param.split(separator);

    if (firstPartIsYear()) {
      year = parseInt(dateParts[0], 10);
      day = parseInt(dateParts[2], 10);
    } else {
      year = parseInt(dateParts[2], 10);
      day = parseInt(dateParts[0], 10);
    }
    month = parseInt(dateParts[1], 10);

    function firstPartIsYear() {
      return dateParts[0].length === 4;
    }

    function getSeparator() {
      try {
        return param.match(/\D/)[0];
      } catch (error) {
        throw new Error('Failed to get separator from ' + param);
      }
    }

  }

  function prepareFromTimestamp () {
    let dateString = moment(dateParam, 'x').format('D.M.YYYY');
    return prepareFromString(dateString);
  }

}


export {
  getDateParts,
};