(function() {
  'use strict';

  angular
    .module('blocks.errorHandler')
    .directive('errorHandler', errorHandler);

  function errorHandler() {

    return {
      link(scope, element, attrs) {
        scope.template = attrs.template || 'blocks/error-handler/error-handler-default-template.html';
      },
      scope: {
        errorHandler: '=handler',
      },
      template: '<ng-include src="template"></ng-include>',
    };

  }

})();
