(function () {
  'use strict';

  angular.module('smAnonymousSafetyReport', [
    'ngSentry',
    'constants',
    'ui.router',
    'ngCookies',
    'ngGeolocation',
    'selectize',
    'blocks.display',
    'blocks.dropsearch',
    'blocks.errorHandler',
    'blocks.logger',
    'ngSanitize',
    'blocks.i18n',
    'blocks.issueMonitoring',
    'blocks.locationHelper',
    'duScroll',
    'oc.lazyLoad',
    'blueimp.fileupload',
  ]);

  var constants = {
    FADEOUT_DURATION: 400, // millisecs
    SYSTEM: 'AR',
    DOCMANAGER_URL: '/monitoring/anonymous/api/v1',
    TIME_TO_INDICATE_SUCCESSFUL_SUBMISSION: 1500, // millisecs
  };

  angular.forEach(constants, function (value, key) {
    angular
      .module('smAnonymousSafetyReport')
      .constant(key, value);
  });

})();
