
//// CSS FiLES START ////

import('../../scss/zeroni.scss');

//// CSS FiLES END ////


// LIB FILES
import '../inc/js/commonIncludes.js';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'jquery';
import 'lodash';
import 'moment';
import 'exif-js/exif.js';
import '@selectize/selectize/dist/js/selectize.js';

import '../../node_modules/angular/angular.js';
import '../inc/helpers/Sentry.js';
import '../inc/helpers/sentryAngularPlugin';
import '../../node_modules/angular-cookies/angular-cookies.js';
import '../../node_modules/ngGeolocation/ngGeolocation.js';
import '../../node_modules/angular-scroll/angular-scroll.js';

import '../../node_modules/moment/locale/fi.js';

import '../../node_modules/@uirouter/core/_bundles/ui-router-core.js';
import '../../node_modules/@uirouter/angularjs/release/ui-router-angularjs.js';
import '../../node_modules/angular-sanitize/angular-sanitize.js';
import 'oclazyload';

// OUR FILES
import '../inc/js/constants.js';

import '../blocks/display/display.module.js';
import '../blocks/display/display.service.js';
import '../blocks/error-handler/error-handler.module.js';
import '../blocks/error-handler/error-handler.js';
import '../blocks/error-handler/error-handler.directive.js';
import '../blocks/location-helper/location-helper.module.js';
import '../blocks/location-helper/location-helper.js';
import '../blocks/logger/logger.module.js';
import '../blocks/logger/logger.js';

import '../blocks/dropsearch/dropsearch.module.js';
import '../inc/js/directives/tm-docs-dropzone.directive.js';
import '../inc/js/controllers/docs-destroy.controller.js';
import '../inc/js/controllers/docs-upload.controller.js';
import '../inc/js/services/docmanager-files.service.js';

import '../blocks/issue-monitoring/issue-monitoring.module.js';
import '../blocks/issue-monitoring/issue-monitoring.service.js';
import '../blocks/i18n/i18n.module.js';
import '../blocks/i18n/messages-de.service.js';
import '../blocks/i18n/messages-en.service.js';
import '../blocks/i18n/messages-et.service.js';
import '../blocks/i18n/messages-fi.service.js';
import '../blocks/i18n/messages-pl.service.js';
import '../blocks/i18n/messages-ru.service.js';
import '../blocks/i18n/messages-sk.service.js';
import '../blocks/i18n/messages-sv.service.js';
import '../blocks/i18n/i18n.service.js';
import '../blocks/i18n/i18n.component.js';
import '../blocks/i18n/i18n-placeholder.directive.js';
import '../blocks/i18n/messages-override.service.js';
import '../blocks/i18n/current-site-service-stump.js';

import '../upload.js';

import './app.js';
import './app.config.js';

//  Modules
// Main app config

import './site-picker.controller.js';
import './report-form.controller.js';

import '../../node_modules/angular-selectize2/dist/angular-selectize.js';
