(function () {
  'use strict';

  angular
    .module('smAnonymousSafetyReport')
    .controller('reportFormController', reportFormController);

  reportFormController.$inject = ['$http', '$cookies',
    '$scope', '$state', '$rootScope', '$timeout',
    'displayService', 'errorHandler', 'i18nService', 'locationHelper',
    'MAX_PHOTO_WIDTH', 'TIME_TO_INDICATE_SUCCESSFUL_SUBMISSION'];

  function reportFormController($http, $cookies,
    $scope, $state, $rootScope, $timeout,
    displayService, errorHandler, i18nService, locationHelper,
    MAX_PHOTO_WIDTH, TIME_TO_INDICATE_SUCCESSFUL_SUBMISSION) {
    $scope.display = displayService;
    var vm = this;

    vm.canBePositive = canBePositive;
    vm.report = { positive: false };
    vm.sendReport = sendReport;
    vm.suggestions = {};
    vm.usesPhoto = usesPhoto;
    vm.usesLocation = usesLocation;
    vm.areaSelectizeConfig = null;
    vm.MAX_PHOTO_WIDTH = MAX_PHOTO_WIDTH;

    activate();

    ////

    $scope.$watch('vm.report.type', scrollToHeading);

    ////

    function activate() {

      if (!$state.params.site) {
        $state.go('^');
      } else {
        vm.selectedSite = $state.params.site;
        initAreaSelectizeConfig();
        initFromCookie();
      }

      function initAreaSelectizeConfig() {
        if (!vm.selectedSite.areas || !vm.selectedSite.areas.length) {
          return;
        }

        vm.areaSelectizeConfig = {
          valueField: 'name',
          labelField: 'name',
          searchField: ['name'],
          sortField: 'name',
          maxItems: 1,
          placeholder: i18nService.get('label.area'),
          options: vm.selectedSite.areas.map(area => ({
            name: area,
          })),
        };
      }

      function initFromCookie () {
        var previousReportJson = $cookies.get('previousAnonymousReport');
        if (previousReportJson) {
          var previousReport = angular.fromJson(previousReportJson);
          if (previousReport.reporterName) {
            vm.report.reporterName = previousReport.reporterName;
          }
          vm.suggestions.area = previousReport.area;
          vm.suggestions.location = previousReport.location;
          vm.suggestions.description = previousReport.description;
          vm.suggestions.date = previousReport.date;
        }
      }

    }

    function requestFailed(error) {
      vm.sending = false;
      errorHandler.prepareHandlerForController('requestErrorHandler', vm);
      vm.requestErrorHandler.addError(error);
    }

    function scrollToHeading(typeValue, oldTypeValue) {
      if (typeValue === oldTypeValue) {
        return;
      }
      if (typeValue) {
        locationHelper.scrollTo('#editor-heading');
      } else {
        locationHelper.scrollTo('#type-btn-group', { scrollOffset: 'halfway' });
      }
    }

    function sendReport() {

      vm.report.image = vm.report.attachments?.reportPhoto?.length && vm.report.attachments.reportPhoto[0].id || null;

      saveToCookie();
      vm.sending = true;
      var url = '/monitoring/anonymous/api/v1/sites/' + $state.params.site.id + '/reports';

      return $http.post(url, vm.report)
        .then(sendingComplete)
        .catch(requestFailed);

      function saveToCookie () {
        if (isAnonymousEmail()) {
          return;
        }

        const previousReport = {
          reporterName: vm.report.reporterName,
          date: moment().format('D.M.YYYY'),
          description: vm.report.description,
          area: vm.report.area,
          location: vm.report.location,
          siteId: vm.selectedSite.id,
        };
        $cookies.putObject('previousAnonymousReport', previousReport);
      }

      function sendingComplete () {
        vm.sending = false;
        vm.sent = true;
        $timeout(function() {
          showSuccessAlert();
          vm.sent = false;
          $state.go('^');
        }, TIME_TO_INDICATE_SUCCESSFUL_SUBMISSION);
      }

    }

    function showSuccessAlert() {
      if (!$rootScope.alerts) {
        $rootScope.alerts = {};
      }
      $rootScope.alerts.reportSent = true;
      $timeout(function () {
        $rootScope.alerts.reportSent = false;
      }, 10000);
    }

    function canBePositive() {
      return !isAnonymousEmail() && vm.report.type !== 'DEFECT';
    }

    function usesLocation() {
      return vm.report.type !== 'BLACKMARKET';
    }

    function usesPhoto() {
      return !isAnonymousEmail();
    }

    function isAnonymousEmail() {
      return vm.report.type === 'BLACKMARKET' || vm.report.type === 'FEEDBACK';
    }

  }

})();
