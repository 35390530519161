(function() {
  'use strict';

  angular
    .module('blocks.i18n')
    .component('i18n', {
      template: '',
      bindings: {
        code: '@',
        text: '@?',
        var: '@?',
        arguments: '<?',
      },
      controller: i18nWithArguments,
    });

  i18nWithArguments.$inject = ['i18nService', '$compile', '$element', '$scope'];

  function i18nWithArguments(i18nService, $compile, $element, $scope) {
    const vm = this;

    this.$onInit = () => {
      updateMessage();
    };

    this.$onChanges = () => {
      updateMessage();
    };

    function updateMessage() {
      if (vm.var && vm.code) {
        $scope.$parent[vm.var] = i18nService.get(vm.code, vm.arguments);
      } else {
        $element.html($compile('<span>' + i18nService.get(vm.code, vm.arguments) + '</span>')($scope));
      }
    }
  }

})();
