(function() {
  'use strict';

  angular
    .module('blocks.locationHelper')
    .service('locationHelper', locationHelper);

  /* @ngInject */
  function locationHelper(
    $document,
    $state,
    $timeout,
    duScrollDuration,
    duScrollOffset,
    logger,
  ) {

    return {
      go,
      goToParent,
      goToStateWithParams: go,
      goToPrevious,
      previousStateIncludes,
      scrollTo: scrollToElement,
      scrollToTop,
    };

    function goToParent(params) {
      $state.go('^', params).then(scrollToTop);
    }

    function go(stateName, params) {
      return $state.go(stateName, params).then(scrollToTop);
    }

    function goToPrevious(params) {
      $state.go(getLatestHistoryEntry()?.name || '^', params).then(scrollToTop);
    }

    function previousStateIncludes(stateName) {
      return getLatestHistoryEntry()?.name.includes(stateName);
    }

    function getLatestHistoryEntry() {
      const history = _.get($state, '_history', []);
      return history[history.length - 1];
    }

    /**
     * Scrolls to an element using angular-scroll's $document.scrollToElementAnimated.
     * The element needs to be found after the next digest cycle.
     * @param  {Element|string} inputElement - Element, or id/class of the element to scroll to
     * @param  {obj} [params] - Additional params (offset in px, duration of the scroll in millisecs; delay in millisecs)
     * @return {undefined}
     */
    function scrollToElement(inputElement, inputParams) {

      if (!inputElement) {
        return;
      }

      const params = getParams();

      return $timeout(tryToScroll, params.delay);

      function tryToScroll() {

        const element = getElement();

        if (!element[0]) {
          logger.warning(`locationHelper.scrollToElement: Tried to scroll to element "${inputElement.toString()}" but it does not exist.`);
          return;
        }

        handleCssClassChanges();

        if (_.get(inputParams, 'scrollOnlyIfOutOfSight') &&
          elementIsInSight(element)) {
          return;
        }
        const scrollHostElement = params.scrollHostElement || $document;
        scrollHostElement.scrollToElementAnimated(element, params.scrollOffset, params.scrollDuration)
          .catch(() => {
            // eslint-disable-next-line
            console.error('Animated scroll rejected');
          });

        function getElement() {
          if (typeof inputElement === 'object') {
            return inputElement;
          } else if (typeof inputElement === 'function') {
            return inputElement();
          } else {
            let prefixedAnchor = inputElement.toString();
            if (prefixedAnchor.substr(0, 1) !== '#' &&
              prefixedAnchor.substr(0, 1) !== '.') {
              prefixedAnchor = '#' + prefixedAnchor;
            }
            return angular.element(prefixedAnchor);
          }
        }

        function handleCssClassChanges() {
          if (_.get(inputParams, 'addClass')) {
            $timeout(() => {
              $(element).addClass(params.addClass);
              $timeout(() => {
                $(element).removeClass(params.addClass);
              }, params.removeClassDelay);
            }, params.addClassDelay);
          }
        }

      }

      function elementIsInSight(element) {
        const elementTopOffset = element.offset().top;
        const scrollTop = $(document).scrollTop();
        const viewportHeight = getViewportHeight();
        const MINIMUM_VISIBLE_HEIGHT = 150; // px
        return elementTopOffset > scrollTop &&
          elementTopOffset < scrollTop + viewportHeight - MINIMUM_VISIBLE_HEIGHT;
      }

      function getViewportHeight() {
        return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      }

      function getParams() {

        const TIME_FOR_ALL_ANIMATIONS_TO_FINISH = 5000;

        const params = {
          addClassDelay: duScrollDuration,
          removeClassDelay: TIME_FOR_ALL_ANIMATIONS_TO_FINISH,
          scrollDuration: duScrollDuration,
          scrollOffset: duScrollOffset,
          delay: 0,
          ...inputParams,
        };

        const MAX_TOP_STICKY_ELEMENTS_HEIGHT = 180;

        if (params.scrollOffset === 'halfway') {
          params.scrollOffset = Math.max(getViewportHeight() / 2, MAX_TOP_STICKY_ELEMENTS_HEIGHT);
        } else if (params.scrollOffset === 'thirdway') {
          params.scrollOffset = Math.max(getViewportHeight() / 3, MAX_TOP_STICKY_ELEMENTS_HEIGHT);
        }
        return params;

      }

    }

    function scrollToTop() {
      $document.scrollTo(0, 0, 0);
    }

  }

})();
