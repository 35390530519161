/* eslint-disable no-console */
import { get } from 'lodash';
import issueMonitoringService from './issueMonitoringService';
import loggerService from './loggerService';

const errorHandler = {
  catcher,
  reject,
  silentCatcher,
};

/**
 * Factory function that returns a new function designed to handle errors.
 *
 * @param {string || Error} jsError Javascript error or string indicating location of error.
 * @param {Object} params Additional parameters.
 * @returns {function} Returns an error catcher function.
 */
function catcher(jsError, params) {
  return function (error) {
    return reject(jsError, error, params);
  };
}

/**
 * Factory function that returns a new function designed to handle errors
 * without producing more errors or rejections.
 *
 * @param {string || Error} jsError Javascript error or string indicating location of error.
 * @returns {function} Returns an error catcher function.
 */
function silentCatcher(jsError) {
  return function (error) {
    console.error(jsError, error);
  };
}

/**
 * Handles an error, returning a rejected promise.
 *
 * Some special conditions exist, e.g. timeout, when the error is
 * not reported to Sentry as an error, but instead as info.
 *
 * @param {string} message Message for error given by the developer.
 * @param {Object} errorObj The error object (SyntaxError, ReferenceError etc)
 * @param {Object} params Additional parameters.
 * @returns {Promise || undefined} Returns an error object in a rejected promise. Aborted by user: return undefined.
 */
function reject(message, errorObj = {}, params) {

  // This function is used heavily with dataservice fetches that also
  // incorporate cancellers. Each cancellation throws an error despite it
  // being very much in line with user's intentions so we'll silently skip
  // those.
  if (requestWasAbortedByUser(errorObj)) {
    const rejectionObj = { message: 'Cancelled' };
    if (errorObj) {
      rejectionObj.data = errorObj;
    }
    return Promise.reject(rejectionObj);
  }

  if (shouldBeReportedAsInfo()) {
    issueMonitoringService.info(message, {
      beforeSend (report) {
        report.updateMetaData('data', errorObj);
      },
    });
  } else {
    loggerService.error(message, errorObj, params);
  }

  const errorParams = { message, data: errorObj.data || errorObj.message };
  return Promise.reject(errorParams);

  function shouldBeReportedAsInfo() {

    return requestTimedOut() ||
      requestCouldNotBeHandledByProxy() ||
      isGenericProxyError() ||
      requestFailedBecauseServiceWasUnavailable();

    function requestTimedOut() {
      return get(errorObj, 'xhrStatus') &&
        get(errorObj, 'status') === -1;
    }

    // Timeouts and 503's believed to be often shown as 502's if a proxy is being used.
    function requestCouldNotBeHandledByProxy() {
      return get(errorObj, 'xhrStatus') &&
        get(errorObj, 'status') === 502;
    }

    function isGenericProxyError() {
      return get(errorObj, 'xhrStatus') &&
        get(errorObj, 'statusText') === 'Proxy Error';
    }

    function requestFailedBecauseServiceWasUnavailable() {
      return get(errorObj, 'xhrStatus') &&
        get(errorObj, 'status') === 503;
    }

  }

  function requestWasAbortedByUser(eObj) {
    return get(eObj, 'xhrStatus') === 'abort' ||
      get(eObj, 'data') && requestWasAbortedByUser(eObj.data); // Nested request data
  }

}

export default errorHandler;
