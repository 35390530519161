angular
  .module('blocks.dropsearch')
  .directive('tmDocsDropzone', tmDocsDropzone);

function tmDocsDropzone() {
  return {
    restrict: 'E',
    transclude: true,
    replace: true,
    templateUrl: '/inc/html/dropzone-docs.html',
    controller: 'docsUploadController as $ctrl',
  };
}
