(function () {

  angular
    .module('blocks.dropsearch')
    .factory('docmanagerFilesService', docmanagerFilesService);

  docmanagerFilesService.$inject = ['$http', '$q', 'logger'];

  function docmanagerFilesService($http, $q, logger) {

    var docmanagerUrl = 'documents/';

    return {
      getFiles,
    };

    function getFiles(dropzoneId) {
      var listUrl = docmanagerUrl + `dropzones/${dropzoneId}/fileRefs`;
      return $http.get(listUrl)
        .then(getFilesComplete)
        .catch(getFilesFailed);
      function getFilesComplete(response) {
        var files = response.data || [];
        for (var i = 0 ; i < files.length ; i++) {
          files[i].url = docmanagerUrl + 'files/' + files[i].id;
        }
        return files;
      }
      function getFilesFailed(error) {
        logger.error(error, 'getFiles.' + error.data);
        return $q.reject('XHR Failed');
      }
    }

  }

})();
